import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"

// components
import Layout from "../components/layout/Layout";

// styles

const TermsOfUsePage = () => {
  const query = useStaticQuery(graphql`
    {
      craft {
        entry(section: "termsOfUse") {
          ... on Craft_termsOfUse_termsOfUse_Entry {
            richTextStandard
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className={`small-page`}>
        <div className={`m-24`} dangerouslySetInnerHTML={{ __html: query.craft.entry.richTextStandard }} />
      </div>
    </Layout>
  )
}

export default TermsOfUsePage
